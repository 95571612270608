import Api from "../Api"


export default{
    getMovs(tipo=0,tipmov=0){
    let strActivo = "";
    let srtMov
    switch(tipo){
        case 0:strActivo="all";break;
        case 1:strActivo="activas";break;
        case 2:strActivo="inactivas";break;
    }
    switch(tipmov){
        case 1:srtMov='?mov=1';break;
        case 2:srtMov='?mov=2';break;
    }
    let url = `/movimientos/${strActivo}${srtMov}`
    
   return  Api() .get(url)
  }
}