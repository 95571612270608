import Api from "../Api"


export default{
    getPacientes(tipo=0){
    //tipo es 2 inactivos, 1 para activos, 0 para todos
    let strActivo = "";
    let strResidente = ""
    switch(tipo){
        case 0:strActivo="all";break;
        case 1:strActivo="activos";break;
        case 2:strActivo="inactivos";break;
    }
    let url = `/pacientes/${strActivo}`
    
   return  Api() .get(url)
  },
  getHospitalizados(){
    return Api().get("/pacientes/hospitalizados")
  },
  getHospitalizadosAll(){
    return Api().get("/pacientes/hospitalizados/all")
  }
}

